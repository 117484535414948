import React from "react";
import { Link } from "gatsby";
import { SocialIcon } from "react-social-icons";

const Layout = props => {
  const { title, children } = props;
  const [toggleNav, setToggleNav] = React.useState(false);
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div id="menu" className="site-head-container">
          <a
            className="nav-burger"
            href={`#menu`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>{" "}
              <div className="hamburger-text-menu-text hidden">Menu</div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              {/*<li className="nav-home nav-current" role="menuitem">
                <Link to={`/`}>Home</Link>
               </li> */}
              <li className="nav-about" role="menuitem">
                <Link to={`/about`}>About</Link>
              </li>
              <li className="nav-teaching" role="menuitem">
                <Link to={`/teaching`}>Teaching</Link>
              </li>
              <li className="nav-media" role="menuitem">
                <Link to={`/media`}>Media</Link>
              </li>
              <li className="nav-contact" role="menuitem">
                <Link to={`/contact`}>Contact</Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {title}
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <table cellPadding="0" cellSpacing="0">
                <tr>
                  <td>
                    <SocialIcon
                      url="https://facebook.com/yang.lu.39"
                      bgColor="white"
                      style={{ height: 32, width: 32 }}
                    />
                  </td>
                  <td>
                    <SocialIcon
                      url="https://www.instagram.com/yyllc_ello/"
                      bgColor="white"
                      style={{ height: 32, width: 32 }}
                    />
                  </td>
                  <td>
                    <SocialIcon
                      url="https://www.youtube.com/channel/UCTNvCtc4DxAs3sfSkTMog1g"
                      bgColor="white"
                      style={{ height: 32, width: 32 }}
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link>
      </footer>
    </div>
  );
};

export default Layout;
